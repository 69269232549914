import PropTypes from "prop-types";
import "./FrameComponent.css";

const FrameComponent = ({ className = "" }) => {
  return (
    <header className={`llogo-1-parent ${className}`}>
      <a href="/">
        <img
          className="llogo-1-icon"
          loading="lazy"
          alt=""
          src="/llogo-1@2x.png"
        />
      </a>
      <div className="navigation-links-wrapper">
        <div className="navigation-links">
          <a className="a" href="/services">услуги</a>
          <a className="a1" href="/cases">кейсы</a>
          <a className="a2" href="/contacts">контакты</a>
          <div className="navigation-icons">
            <div className="icons8-100-1-wrapper">
              <a href="mailto:info@extractix.ru">
                <img
                  className="icons8-100-1"
                  loading="lazy"
                  alt=""
                  src="/icons8100-1@2x.png"
                />
              </a>
            </div>
            <div className="international-icon-wrapper">
              <a href="https://t.me/feyano/" target="_blank" rel="noopener noreferrer">
                <img
                  className="international-icon"
                  loading="lazy"
                  alt=""
                  src="/vector.svg"
                />
              </a>
            </div>
            <img className="icons8-100-11" alt="" src="/icons8100-1-1@2x.png" />
          </div>
        </div>
      </div>
    </header>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
