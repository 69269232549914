import React from 'react';

function Verify() {
  return (
    <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      </head>
      <body>Verification: 2c514849228c8004</body>
    </html>
  );
}

export default Verify;
