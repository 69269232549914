import React, { useState, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import './FrameComponent2.css';

interface FormData {
  name: string;
  surname: string;
  email: string;
  projectDescription: string;
}

const FrameComponent2 = ({ className = "" }) => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    surname: '',
    email: '',
    projectDescription: ''
  });

  const [buttonState, setButtonState] = useState({
    text: 'Будем сотрудничать',
    color: '#0056b3',
    gif: '/handshake-telegram.gif',
  });

  // Обработчик изменений полей ввода
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // Функция отправки данных
  const sendData = async () => {
    try {
      const response = await fetch('https://extractix.ru/api/submit/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Обработка ответа
      const result = await response.json();
      console.log('Успешно отправлено:', result);

      // Обновляем состояние кнопки после успешной отправки
      setButtonState({
        text: 'Мы скоро ответим',
        color: '#005300',
        gif: '/writing-hand-telegram.gif',
      });
    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
      // Вы можете обработать ошибку, например, установить текст кнопки на "Ошибка"
      setButtonState({
        text: 'Ошибка отправки',
        color: '#b30000',
        gif: '/error.gif', // замените на реальный путь к изображению ошибки, если есть
      });
    }
  };

  // Обработчик нажатия на кнопку
  const handleClick = () => {
    sendData();
  };

  return (
    <section className={`contact-content-parent ${className}`}>
      <div className="contact-content">
        <div className="group">
          <h2 className="h2">Напишите нам!</h2>
          <div className="contact-form">
            <div className="div3 podpis">
              Мы подберем лучшее решение для вашего проекта
            </div>
            <div className="inputs">
              <div className="input-fields">
                <div className="field-labels">
                  <div className="field-names">
                    <div className="div3">Ваше имя</div>
                    <input
                      className="lastname-inner"
                      type="text"
                      name="name"
                      placeholder="Введите ваше имя"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="field-names">
                    <div className="div3">Ваша фамилия</div>
                    <input
                      className="lastname-inner"
                      type="text"
                      name="surname"
                      placeholder="Введите вашу фамилию"
                      value={formData.surname}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="field-names">
                    <div className="div3">Ваш почтовый адрес</div>
                    <input
                      className="lastname-inner"
                      type="text"
                      name="email"
                      placeholder="Введите почтовый адрес"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="field-names">
                    <div className="div6">Небольшое описание проекта</div>
                    <textarea
                      className="lastname-inner-big"
                      rows={4}
                      name="projectDescription"
                      placeholder="Опишите ваш проект"
                      value={formData.projectDescription}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="button-container">
              <div
                className="button"
                style={{ backgroundColor: buttonState.color }}
                onClick={handleClick}
              >
                <span className="button-text">{buttonState.text}</span>
                <img
                  src={buttonState.gif}
                  alt="Gif"
                  className="button-icon"
                />
              </div>
              <span className="or-text">или</span>
              <a
                href="https://t.me/feyano/"
                className="telegram-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/telegram.png" alt="Telegram" className="telegram-icon" />
                <span className="telegram-text">Написать нам в Telegram</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <img className="contact-image-icon" alt="" src="/vector-4.svg" />
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
