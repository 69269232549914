import PropTypes from "prop-types";
import "./ServicesDescription.css";

const ServicesDescription = ({ className = "" }) => {
  return (
    <div className={`services-description ${className}`}>
      <div className="api-wrapper">
        <div className="api">
          Создаем парсеры для самых крупных сервисов и интегрируем внешние и
          защищенные API:
        </div>
      </div>
      <div className="scroll-section">
        <img className="icons8-512-1" loading="lazy" alt="" src="/icons8512-1@2x.png" />
        <img className="icons8-youtube-500-1" loading="lazy" alt="" src="/icons8youtube500-1@2x.png" />
        <img className="icons8-youtube-500-1" loading="lazy" alt="" src="/icons8ebay500-1@2x.png" />
        <img className="icons8-youtube-500-1" loading="lazy" alt="" src="/icons8vk500-1@2x.png" />
        <img className="icons8-youtube-500-1" loading="lazy" alt="" src="/instagram.png" />
        <img className="icons8-youtube-500-1" loading="lazy" alt="" src="/yandex.png" />
        <img className="icons8-512-1" loading="lazy" alt="" src="/icons8512-1@2x.png" />
        <img className="icons8-youtube-500-1" loading="lazy" alt="" src="/icons8youtube500-1@2x.png" />
        <img className="icons8-youtube-500-1" loading="lazy" alt="" src="/icons8ebay500-1@2x.png" />
        <img className="icons8-youtube-500-1" loading="lazy" alt="" src="/icons8vk500-1@2x.png" />
        <img className="icons8-youtube-500-1" loading="lazy" alt="" src="/yandex.png" />
        <img className="icons8-512-1" loading="lazy" alt="" src="/icons8512-1@2x.png" />
      </div>
    </div>
  );
};

ServicesDescription.propTypes = {
  className: PropTypes.string,
};

export default ServicesDescription;
