import React, { useEffect } from "react";
import FrameComponent from "../components/FrameComponent";
import ServicesDescription from "../components/ServicesDescription";
import FrameComponent1 from "../components/FrameComponent1";
import FrameComponent2 from "../components/FrameComponent2";
import "./Root.css";

// Функция для создания хакерской анимации текста
function hackerEffect(element: HTMLElement) {
  const originalText = element.innerText;
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let iterations = 0;

  const interval = setInterval(() => {
    element.innerText = originalText
      .split('')
      .map((letter, index) => {
        if (index < iterations) {
          return originalText[index];
        }
        return letters[Math.floor(Math.random() * letters.length)];
      })
      .join('');

    if (iterations >= originalText.length) {
      clearInterval(interval);
    }

    iterations += 1 / 3; // Скорость анимации
  }, 30); // Интервал обновления текста
}

const Root: React.FC = () => {
  useEffect(() => {
    const hackerTextElement = document.getElementById("hackerText");
    if (hackerTextElement) {
      hackerEffect(hackerTextElement);
    }
  }, []);

  return (
    <div className="root">
      <FrameComponent />
      <section className="frame-parent">
        <div className="frame-wrapper">
          <div className="parent">
            <div className="div">
              <p className="p" id="hackerText">
                Инновационные решения для парсинга данных
              </p>
            </div>
            <div className="div1">
              Предоставляем мощные инструменты для автоматизации и анализа
              данных, помогая клиентам извлекать ценные инсайты из больших
              объемов информации.
            </div>
          </div>
        </div>
        <img className="hero-image-icon" alt="" src="/vector-1.svg" />
      </section>
      <section className="services-description-parent">
        <ServicesDescription />
        <FrameComponent1 />
      </section>
      <FrameComponent2 />
      <section className="footer">
        <div className="footer-content">
          <div className="copyright">+79192584373</div>
        </div>
        <footer className="footer-content1">
          <div className="div2">© Канатов В.Р., 2024</div>
          <div className="infoextractixru">info@extractix.ru</div>
        </footer>
      </section>
    </div>
  );
};

export default Root;
