import PropTypes from "prop-types";
import "./FrameComponent1.css";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <div className={`technologies-description-parent ${className}`}>
      <div className="technologies-description">
        <img className="technologies-image-icon" alt="" src="/vector-2.svg" />
        <div className="technologies-paragraph-wrapper">
          <div className="technologies-paragraph">
            <div className="e4c">
              Мы используем самые современные методы и инструменты разработки
              парсеров
            </div>
            <div className="tech-stack">
              <div className="stack-row-one">
                <div className="stack-first-pair">
                  <div className="language-icons">
                    <img
                      className="icons8-python-500-1"
                      loading="lazy"
                      alt=""
                      src="/icons8python500-1@2x.png"
                    />
                  </div>
                  <img
                    className="go-logo-white-1-icon"
                    loading="lazy"
                    alt=""
                    src="/gologo-white-1@2x.png"
                  />
                </div>
                <img
                  className="docker-logo-white-1-icon"
                  loading="lazy"
                  alt=""
                  src="/dockerlogowhite-1@2x.png"
                />
              </div>
              <div className="stack-row-two">
                <div className="stack-second-pair">
                  <div className="backend-icons">
                    <img
                      className="bs4-photoroom-1-icon"
                      loading="lazy"
                      alt=""
                      src="/bs4photoroom-1@2x.png"
                    />
                    <div className="kubernetes-icon">
                      <img
                        className="kubernetes-1-icon"
                        loading="lazy"
                        alt=""
                        src="/kubernetes-1@2x.png"
                      />
                    </div>
                  </div>
                  <div className="selenium-icon">
                    <img
                      className="selenium-1-icon"
                      loading="lazy"
                      alt=""
                      src="/selenium-1@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="technologies-image-icon" alt="" src="/vector-3.svg" />
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
