import React, { useEffect } from "react";
import FrameComponent from "../components/FrameComponent";
import "./Oops.css";

function hackerEffect(element: HTMLElement) {
  const originalText = element.innerText;
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let iterations = 0;

  const interval = setInterval(() => {
    element.innerText = originalText
      .split('')
      .map((letter, index) => {
        if (index < iterations) {
          return originalText[index];
        }
        return letters[Math.floor(Math.random() * letters.length)];
      })
      .join('');

    if (iterations >= originalText.length) {
      clearInterval(interval);
    }

    iterations += 1 / 3;
  }, 30);
}

const Oops: React.FC = () => {
  useEffect(() => {
    const hackerTextElement = document.getElementById("hackerText");
    if (hackerTextElement) {
      hackerEffect(hackerTextElement);
    }
  }, []);

  return (
    <div className="error">
      <FrameComponent />
      <section className="frame-parent">
        <div className="frame-wrapper">
          <div className="parent">
            <div className="div">
              <p className="p" id="hackerText">
                Упс..
              </p>
            </div>
            <div className="div1">
              Эта страница скоро будет доступна&nbsp;;&#41;
            </div>
          </div>
        </div>
        <img className="hero-image-icon" alt="" src="/vector-1.svg" />
      </section>
      <footer className="footer-error">
        <div className="footer-content-error">
          <div className="copyright-error">+79192584373</div>
        </div>
        <div className="footer-content1-error">
          <div className="div2-error">© Канатов В.Р., 2024</div>
          <div className="infoextractixru-error">info@extractix.ru</div>
        </div>
      </footer>
    </div>
  );
};

export default Oops;