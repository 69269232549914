import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Root from "./pages/Root";
import Oops from "./pages/Oops";
import Verify from "./pages/Verify"

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Extractix - innovative solutions for data parsing";
        metaDescription = "Welcome to the homepage";
        break;
      default:
        title = "Extractix - Oops..";
        metaDescription = "The page you are looking for does not exist.";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route
        path="/yandex_2c514849228c8004.html"
        element={<Verify />}
      />
      <Route path="*" element={<Oops />} />
    </Routes>
  );
}
export default App;
